import { createContext, useEffect, useMemo, useState } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { createTheme, ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { isNonProdEnv } from '../lib/util/env-tools';
import themeDefault from '../theme';

library.add(fab);

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkMode, setDarkMode] = useState(isNonProdEnv ? prefersDarkMode : false);

  useEffect(() => {
    setDarkMode(isNonProdEnv ? prefersDarkMode : false);
  }, [prefersDarkMode]);

  const theme = useMemo(
    () =>
      createTheme({
        ...themeDefault,
        components: {
          ...themeDefault.components,
          MuiAppBar: {
            styleOverrides: {
              colorPrimary: {
                backgroundColor: darkMode ? '#121212' : 'white'
              }
            }
          },
          MuiTypography: {
            styleOverrides: {
              colorPrimary: {
                color: darkMode ? 'white' : 'black'
              }
            }
          }
        },
        palette: {
          mode: darkMode ? 'dark' : 'light',
          primary: {
            main: darkMode ? '#fff' : '#282b4c'
          },
          secondary: {
            main: '#e85d0c'
          }
        }
      }),
    [darkMode]
  );

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode, theme }}>
      <MaterialThemeProvider theme={theme}>{children}</MaterialThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
