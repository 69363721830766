import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function ContactInformation({ data, setDataInfoUser }) {
  const { t } = useTranslation('config');
  const [dataUser, setDataUser] = useState({
    correo: '',
    correoValidado: false,
    telefono: '',
    telefonoValidado: false
  });

  const [editMode, setEditMode] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [code, setCode] = useState('');
  const [fieldToValidate, setFieldToValidate] = useState('');
  const [validationStatus, setValidationStatus] = useState('');
  const [editedUser, setEditedUser] = useState(dataUser);
  const { accounts } = useMsal();
  const { i18n } = useTranslation('nav');
  const locale = i18n.resolvedLanguage;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (data?.contacto) {
      const contactoData = {
        correo: data.contacto.correo_electronico || '',
        correoValidado: data.contacto.email_verificado,
        telefono: data.contacto.numero_telefonico || '',
        telefonoValidado: data.contacto.telefono_verificado
      };
      setDataUser(contactoData);
      setEditedUser(contactoData);
    } else {
      const defaultData = {
        correo: '',
        correoValidado: false,
        telefono: '',
        telefonoValidado: false
      };
      setDataUser(defaultData);
      setEditedUser(defaultData);
    }
  }, [data]);

  const validateEmail = email => /\S+@\S+\.\S+/.test(email);
  const validatePhone = phone => /^\+?\d+$/.test(phone);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setEditedUser(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSave = async () => {
    const { correo, telefono } = editedUser;

    // Validación de datos de entrada solo si están presentes
    if (correo && !validateEmail(correo)) {
      setSnackbarMessage(t('profile.alertEmail'));
      setSnackbarOpen(true);
      return;
    }

    if (telefono && !validatePhone(telefono)) {
      setSnackbarMessage(t('profile.alertPhone'));
      setSnackbarOpen(true);
      return;
    }

    try {
      // Solo realizar la actualización si ha habido cambios
      if (correo !== dataUser.correo || telefono !== dataUser.telefono) {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/updatePhoneAndEmailUser/${accounts[0].idTokenClaims.sub}`, {
          correo_electronico: correo || null, // Si el correo es vacío, enviar null
          numero_telefonico: telefono || null // Si el teléfono es vacío, enviar null
        });

        console.log('res:', res.data);

        // Mostrar el mensaje recibido desde el backend
        setSnackbarMessage(res.data.message);
        setSnackbarOpen(true);

        // Recargar los datos del usuario del backend
        await reloadUserInformation();

        // También actualizar dataInfoUser en el componente padre (ProfilePage)
        setDataInfoUser(prevData => ({
          ...prevData,
          contacto: {
            ...prevData.contacto,
            correo_electronico: correo,
            email_verificado: false, // Reiniciar verificación tras cambio
            numero_telefonico: telefono,
            telefono_verificado: false // Reiniciar verificación tras cambio
          }
        }));
      }
    } catch (error) {
      console.log('Error al guardar los cambios:', error);

      // Mostrar un mensaje si el correo o teléfono ya están en uso
      if (error.response && error.response.status === 400) {
        const errorMessage = error.response.data.message;
        if (errorMessage.includes('correo')) {
          setSnackbarMessage(t('profile.contactEmailError'));
        } else if (errorMessage.includes('teléfono')) {
          setSnackbarMessage(t('profile.contactPhoneError'));
        } else {
          setSnackbarMessage(errorMessage); // Mostrar el mensaje tal cual
        }
      } else {
        setSnackbarMessage('profile.changesError');
      }
      setSnackbarOpen(true);
    }

    setEditMode(false);
  };

  const sendVerificationCode = async (field, value) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/verify-contact-medium`, {
        [field]: value,
        language: locale,
        userId: accounts[0].idTokenClaims.sub
      });
      console.log(res);
      setModalContent(t('profile.textValidation', { contactMethod: field === 'email' ? t('profile.email') : t('profile.phone') }));
      setOpenModal(true);
    } catch (error) {
      console.error(`Error al enviar el código al ${field}:`, error);
      setModalContent(t('profile.alertError', { contactMethod: field === 'email' ? t('profile.email') : t('profile.phone') }));
      setOpenModal(true);
    }
  };

  const handleValidate = async field => {
    if (field === 'correo') {
      sendVerificationCode('email', editedUser.correo);
    } else {
      sendVerificationCode('phone', editedUser.telefono);
    }
    setFieldToValidate(field);
  };

  const handleCodeSubmit = async () => {
    const field = fieldToValidate === 'correo' ? 'email' : 'phone';
    const editedField = fieldToValidate === 'correo' ? 'correo' : 'telefono';

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/verify-contact-medium`, {
        [field]: editedUser[editedField],
        language: locale,
        userId: accounts[0].idTokenClaims.sub,
        verificationCode: parseInt(t(code), 10)
      });
      console.log('res:', res.data);
      if (res.data.successInd) {
        setValidationStatus(`${fieldToValidate === 'correo' ? 'Correo' : 'Teléfono'} validado correctamente.`);
        setDataUser(prev => ({
          ...prev,
          [`${fieldToValidate}Validado`]: true
        }));
        handleCloseModal();
      } else {
        setValidationStatus('El código ingresado es incorrecto.');
      }
    } catch (error) {
      console.error('Error al validar el código', error);
      setValidationStatus('Error al validar el código. Inténtalo nuevamente.');
    }
  };

  const reloadUserInformation = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/contactosUsuario/${accounts[0].idTokenClaims.sub}`);
      console.log('response:', response.data);
      setDataUser({
        correo: response.data.contacto.correo_electronico || 'No disponible',
        correoValidado: response.data.contacto.email_verificado,
        telefono: response.data.contacto.numero_telefonico || 'No disponible',
        telefonoValidado: response.data.contacto.telefono_verificado
      });
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCode('');
    setValidationStatus('');
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography gutterBottom variant="h4">
        {t('profile.contactInformation')}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <Box display="flex" flexDirection="column">
                  {/* Etiqueta de Correo electrónico */}
                  <span>{t('profile.email')} :</span>

                  {/* Correo mostrado */}
                  {editMode ? (
                    <TextField
                      fullWidth
                      label={t('profile.email')}
                      name="correo"
                      onChange={handleInputChange}
                      value={editedUser.correo}
                      variant="outlined"
                    />
                  ) : (
                    <span>{dataUser.correo || ''}</span>
                  )}

                  {/* Mensaje debajo del correo electrónico, si el correo es null o vacío */}
                  {!dataUser.correo && (
                    <Typography display="block" variant="caption">
                      {t('profile.defaultEmailMessage', 'Por default, utilizaremos el correo electrónico proporcionado en el registro')}
                    </Typography>
                  )}
                </Box>
              </TableCell>
              <TableCell align="right">
                <Button
                  color={dataUser.correoValidado ? 'success' : 'error'}
                  disabled={dataUser.correoValidado}
                  onClick={() => handleValidate('correo')}
                  variant="contained"
                >
                  {dataUser.correoValidado ? 'Validado' : 'Validar'}
                </Button>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                <Box display="flex" flexDirection="column">
                  {/* Etiqueta de Teléfono */}
                  <span>{t('profile.phone')} :</span>

                  {/* Teléfono mostrado */}
                  {editMode ? (
                    <TextField
                      fullWidth
                      label={t('profile.phone')}
                      name="telefono"
                      onChange={handleInputChange}
                      value={editedUser.telefono}
                      variant="outlined"
                    />
                  ) : (
                    <span>{dataUser.telefono}</span>
                  )}
                </Box>
              </TableCell>
              <TableCell align="right">
                <Button
                  color={dataUser.telefonoValidado ? 'success' : 'error'}
                  disabled={dataUser.telefonoValidado}
                  onClick={() => handleValidate('telefono')}
                  variant="contained"
                >
                  {dataUser.telefonoValidado ? 'Validado' : 'Validar'}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog onClose={handleCloseModal} open={openModal}>
        <DialogTitle>{t('profile.validation')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalContent}</DialogContentText>
          <TextField
            autoFocus
            fullWidth
            label={t('profile.codeVerification')}
            margin="dense"
            onChange={e => setCode(e.target.value)}
            value={code}
            variant="outlined"
          />
          {validationStatus && <Typography color={validationStatus.includes('correctamente') ? 'success' : 'error'}>{validationStatus}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button color="primary" onClick={handleCodeSubmit}>
            Validar Código
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
        {editMode ? (
          <Button color="primary" onClick={handleSave} variant="contained">
            Guardar Cambios
          </Button>
        ) : (
          <Button color="secondary" onClick={toggleEditMode} variant="contained">
            Editar
          </Button>
        )}
      </Box>

      <Snackbar autoHideDuration={6000} message={snackbarMessage} onClose={handleSnackbarClose} open={snackbarOpen} />
    </Box>
  );
}

export default ContactInformation;
