import React from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function GeneralInformation({ data }) {
  const { t } = useTranslation('config');

  // Asegúrate de que 'data' y los campos del objeto estén disponibles
  if (!data || !data.datosGenerales) {
    return (
      <Box sx={{ padding: 2 }}>
        <Typography variant="h4">{t('profile.generalInformation')}</Typography>
        <Typography variant="body1">{t('profile.noGeneralInformation')}</Typography>
      </Box>
    );
  }

  const { nombre, apellido_paterno, apellido_materno, correo, telefono } = data.datosGenerales;

  return (
    <Box sx={{ padding: 2 }}>
      <Typography gutterBottom variant="h4">
        {t('profile.generalInformation')}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('profile.name')}:
              </TableCell>
              <TableCell>{`${nombre} ${apellido_paterno} ${apellido_materno}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('profile.email')}:
              </TableCell>
              <TableCell>{correo}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('profile.phone')}:
              </TableCell>
              <TableCell>{telefono}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default GeneralInformation;
