import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Grid, Snackbar, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const EditDaysRemaining = () => {
  const { t } = useTranslation('config');
  const [days, setDays] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Function to get the current number of days from the database
  const fetchDays = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/accountDeletionDays`)
      .then(response => {
        console.log('Response from API:', response.data);
        if (response.data && response.data.length > 0) {
          setDays(response.data[0].registro_dias);
        }
      })
      .catch(error => {
        console.error('Error fetching days:', error);
      });
  };

  useEffect(() => {
    fetchDays();
  }, []);

  const handleDaysChange = e => {
    setDays(parseInt(e.target.value, 10));
  };

  const handleUpdateDays = () => {
    axios
      .patch(`${process.env.REACT_APP_API_URL}/accountDeletionDays`, { registro_dias: days })
      .then(response => {
        console.log('Update response:', response.data);
        setSnackbarMessage(`${t('acountDeletionDays.alertSuccess')}`);
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
      })
      .catch(error => {
        console.error('Error updating days:', error);
        setSnackbarMessage(`${t('acountDeletionDays.alertError')}`);
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div>
      <Box
        sx={{
          '@media (min-width:1280px)': {
            width: '40%'
          },
          '@media (min-width:600px)': {
            width: '80%'
          },
          '@media (min-width:960px)': {
            width: '60%'
          },
          alignItems: 'center',
          border: '1px solid #ddd',
          borderRadius: 2,
          boxShadow: 3,
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          maxWidth: 400,
          mt: 5,
          padding: 4,
          width: '90%' // Make the Box responsive
        }}
      >
        <Typography gutterBottom variant="h6">
          {t('acountDeletionDays.editDays')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth id="days" label={t('acountDeletionDays.daysBefore')} onChange={handleDaysChange} type="number" value={days} />
          </Grid>
          <Grid item xs={12}>
            <Button color="primary" fullWidth onClick={handleUpdateDays} variant="contained">
              {t('acountDeletionDays.update')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar autoHideDuration={6000} onClose={handleCloseSnackbar} open={openSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EditDaysRemaining;
