import { memo } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { TopMenu } from 'src/components/Navigation';
import RegistryComponent from 'src/components/pages/RegistryComponent';

function ActivationLayout() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar disableGutters>
          <TopMenu />
        </Toolbar>
      </AppBar>
      <Box component="main" minHeight="100vh">
        <Toolbar />
        <RegistryComponent />
      </Box>
    </Box>
  );
}

export default memo(ActivationLayout);
