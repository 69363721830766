import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DefaultLayout from 'src/components/layouts/DefaultLayout';

function SalesTracking() {
  const { t } = useTranslation('config');
  const [value, setValue] = useState('Cotizaciones'); // Valor inicial para la pestaña seleccionada

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DefaultLayout>
      <Box sx={{ padding: 2 }}>
        <Typography gutterBottom variant="h4">
          {t('salesTracking.title')}
        </Typography>

        {/* Tabs para navegar entre Cotizaciones, Prospectos y Clientes */}
        <Tabs aria-label="Sales tracking tabs" indicatorColor="secondary" onChange={handleChange} textColor="secondary" value={value}>
          <Tab label={t('salesTracking.quote')} value="Cotizaciones" />
          <Tab label={t('salesTracking.prospect')} value="Prospectos" />
          <Tab label={t('salesTracking.client')} value="Clientes" />
        </Tabs>

        <Box sx={{ padding: 3 }}>
          {/* Contenido dinámico basado en la pestaña seleccionada */}
          {value === 'Cotizaciones' && <Typography variant="body1">Aquí puedes ver y gestionar tus cotizaciones.</Typography>}
          {value === 'Prospectos' && <Typography variant="body1">Aquí puedes gestionar tus prospectos.</Typography>}
          {value === 'Clientes' && <Typography variant="body1">Aquí puedes ver la lista de tus clientes.</Typography>}
        </Box>
      </Box>
    </DefaultLayout>
  );
}

export default SalesTracking;
