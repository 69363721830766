import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18next
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultNS: 'global',
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false
    },
    ns: ['auth', 'config', 'global', 'nav', 'registry']
  });

export default i18next;
