// import { Roboto } from 'next/font/google';

// const roboto = Roboto({
//   display: 'swap',
//   subsets: ['latin'],
//   weight: ['300', '400', '500', '700']
// });

const theme = {
  components: {
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.severity === 'info' && {
            backgroundColor: '#60a5fa'
          })
        })
      }
    }
  },
  palette: {
    mode: 'dark'
  }
  // typography: {
  //   fontFamily: roboto.style.fontFamily
  // }
};

export default theme;
