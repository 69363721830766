import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Alert, Box, Button, Grid, Snackbar, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { loginRequest } from 'src/authConfig';

const EditSessionLifeMinutes = () => {
  const { t } = useTranslation('config');
  const { instance, accounts } = useMsal();
  const [minutes, setMinutes] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [data, setData] = useState({
    date_modification: '',
    id_sessionLife: null,
    id_superadmin: '',
    vida_session: ''
  });

  const fetchMinutes = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/last-session-modification-date`);
      console.log('Response from API:', res);
      if (res.status === 200) {
        // Asegúrate de que la condición del if sea válida
        setData({ id_sessionLife: res.data.id });
        console.log('Minutes:', res.data.vida_session);
        setMinutes(res.data.vida_session);
      }
    } catch (error) {
      console.log('Error fetching minutes:', error);
    }
  };

  useEffect(() => {
    fetchMinutes();
  }, []);

  const handleMinutesChange = e => {
    setMinutes(parseInt(e.target.value, 10));
  };

  const handleUpdateMinutes = async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      });

      const id_superadmin = response.idTokenClaims.oid || response.idTokenClaims.sub;
      data.date_modification = new Date().toISOString();
      data.id_superadmin = id_superadmin;

      console.log('Data:', data);

      const res = await axios.put(`${process.env.REACT_APP_API_URL}/sessionLife`, {
        date_modification: data.date_modification,
        id_superadmin: data.id_superadmin,
        vida_session: minutes
      });

      console.log('Update response:', res);
      setSnackbarMessage(`${t('editMinute.alertSuccess')}`);
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error updating minutes:', error);
      setSnackbarMessage(`${t('editMinute.alertError')}`);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div>
      <Box
        sx={{
          '@media (min-width:1280px)': {
            width: '40%'
          },
          '@media (min-width:600px)': {
            width: '80%'
          },
          '@media (min-width:960px)': {
            width: '60%'
          },
          alignItems: 'center',
          border: '1px solid #ddd',
          borderRadius: 2,
          boxShadow: 3,
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          maxWidth: 400,
          mt: 5,
          padding: 4,
          width: '90%' // Make the Box responsive
        }}
      >
        <Typography gutterBottom variant="h6">
          {t('editMinute.editSessionLifetimeMinutes')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="minutes"
              label={t('editMinute.sessionLifetimeMinutes')}
              onChange={handleMinutesChange}
              type="number"
              value={minutes}
            />
          </Grid>
          <Grid item xs={12}>
            <Button color="primary" fullWidth onClick={handleUpdateMinutes} variant="contained">
              {t('update')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar autoHideDuration={6000} onClose={handleCloseSnackbar} open={openSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EditSessionLifeMinutes;
