import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { msalConfig } from './authConfig';

import './i18n';

if (!process.env.JEST_WORKER_ID) {
  const msalInstance = new PublicClientApplication(msalConfig);

  const root = ReactDOM.createRoot(document.getElementById('root'));

  /**
   * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
   */
  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </React.StrictMode>
  );
}
