import React, { useEffect, useState } from 'react';
import ArchiveIcon from '@mui/icons-material/Archive';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

// Icono genérico de archivo para otros tipos de archivos
const fileIcon = 'https://image.shutterstock.com/image-vector/document-file-icon-260nw-1386696432.jpg'; // Reemplaza con la URL del ícono que desees usar

function AttachedDocuments({ userId }) {
  const { t } = useTranslation('config');
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDocuments = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/business-partner-docs/${userId}`);
        const { blobList, successInd, message } = response.data;

        if (successInd && blobList) {
          setDocuments(blobList); // Guardamos los documentos
        } else if (message === 'No documents attached for this business partner') {
          setDocuments([]); // Si no hay archivos, devolvemos una lista vacía
        }
      } catch (err) {
        console.error('Error fetching documents:', err);
        setError(t('profile.noFetchingDocuments'));
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, [userId, t]);

  if (loading) {
    return (
      <Box sx={{ padding: 2 }}>
        <Typography>{t('profile.loadingDocuments')}</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: 2 }}>
        <Typography variant="h4">{t('profile.attachedDocuments')}</Typography>
        <Typography variant="body1">{error}</Typography>
      </Box>
    );
  }

  if (documents.length === 0) {
    return (
      <Box sx={{ padding: 2 }}>
        <Typography variant="h4">{t('profile.attachedDocuments')}</Typography>
        <Typography variant="body1">{t('profile.noDocuments')}</Typography>
      </Box>
    );
  }

  // Función para determinar si el archivo es una imagen
  const isImage = filename => {
    const ext = filename.split('.').pop().toLowerCase();
    return ['jpg', 'jpeg', 'png', 'gif'].includes(ext);
  };

  // Función para determinar si el archivo es un PDF
  const isPDF = filename => {
    return filename.split('.').pop().toLowerCase() === 'pdf';
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography gutterBottom variant="h4">
        {t('profile.attachedDocuments')}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {documents.map((doc, index) => (
              <TableRow key={index}>
                <TableCell>
                  {doc.filename.split('/').pop()} {/* Mostramos solo el nombre del archivo */}
                </TableCell>
                <TableCell>
                  {isImage(doc.filename) ? (
                    <Box sx={{ marginTop: 2, maxWidth: 100 }}>
                      {/* Previsualización de imagen */}
                      <img alt={doc.filename} src={doc.url} style={{ borderRadius: 8, width: '100%' }} />
                    </Box>
                  ) : isPDF(doc.filename) ? (
                    <ArchiveIcon fontSize="large" /> // Usamos el ícono de archivo para PDFs
                  ) : (
                    <Box sx={{ marginTop: 2, maxWidth: 100 }}>
                      {/* Mostramos un ícono genérico para otros archivos */}
                      <img alt="File icon" src={fileIcon} style={{ width: '100%' }} />
                    </Box>
                  )}
                </TableCell>
                <TableCell align="right">
                  <Button color="primary" href={doc.url} rel="noopener noreferrer" target="_blank" variant="contained">
                    {t('profile.download')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default AttachedDocuments;
