import React from 'react';
import { Box, Checkbox, FormControlLabel, Link, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const errorStyles = {
  color: '#d32f2f', // Color del texto de error
  fontSize: '0.75rem', // Tamaño del texto de error (12px)
  minHeight: '1.125rem', // Asegura que el espacio para el error esté reservado
  textAlign: 'left' // Alinea el texto a la izquierda
};

export default function RegistryTermsconditions({ handleChange, data, errors }) {
  const { t } = useTranslation('registry');

  return (
    <Box sx={{ textAlign: 'left' }}>
      <Typography component="h1" paddingTop={2} sx={{ color: '#E85D0C', fontWeight: 'bold', my: 1 }} variant="h5">
        {t('TermsAndConditions.title')}
      </Typography>

      {/* Nuevo texto y checkboxes para aceptación de información */}
      <Typography sx={{ color: '#282B4C', my: 2 }} variant="body1">
        {t('TermsAndConditions.productAndServices')}{' '}
        <Typography component="span" sx={{ color: '#E85D0C', fontWeight: 'bold' }}>
          {t('TermsAndConditions.entrypay')}
        </Typography>{' '}
        {t('TermsAndConditions.through')}:
      </Typography>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={data.notificacionTelefono}
              name="notificacionTelefono"
              onChange={handleChange}
              sx={{
                '&.Mui-checked': {
                  color: '#E85D0C'
                },
                color: '#E85D0C'
              }}
            />
          }
          label={<Box sx={{ color: '#282B4C' }}>{t('TermsAndConditions.phone')}</Box>}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={data.notificacionCorreo}
              name="notificacionCorreo"
              onChange={handleChange}
              sx={{
                '&.Mui-checked': {
                  color: '#E85D0C'
                },
                color: '#E85D0C'
              }}
            />
          }
          label={<Box sx={{ color: '#282B4C' }}>{t('TermsAndConditions.email')}</Box>}
        />
      </Box>
      <Box sx={{ ...errorStyles }}>{errors.preferenciaNotificacion}</Box>

      {/* Resto del componente */}
      <Box sx={{ my: 2 }}>
        <Typography sx={{ color: '#282B4C', fontSize: '1.2rem', fontWeight: 'bold', textAlign: 'left' }}>
          {t('TermsAndConditions.captcha')}
        </Typography>
        <Box gap={2} sx={{ alignItems: 'center', display: 'flex' }}>
          <Box
            sx={{
              alignItems: 'center',
              border: '1px solid #E85D0C',
              borderRadius: '20px',
              display: 'flex',
              height: '56px',
              justifyContent: 'center',
              marginTop: 1.1,
              minWidth: '100px'
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }} variant="h6">
              {data.captcha}
            </Typography>
          </Box>
          <TextField
            error={!!errors.captchaInput}
            inputProps={{ maxLength: 6 }}
            label={t('TermsAndConditions.code')}
            margin="normal"
            name="captchaInput"
            onChange={handleChange}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: 'darkblue'
                },
                borderRadius: '20px'
              }
            }}
            value={data.captchaInput}
            variant="outlined"
          />
        </Box>
        <Box sx={{ ...errorStyles }}>{errors.captchaInput}</Box>
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={data.termsAccepted}
            name="termsAccepted"
            onChange={handleChange}
            sx={{
              '&.Mui-checked': {
                color: '#E85D0C'
              },
              color: '#E85D0C'
            }}
          />
        }
        label={
          <Box sx={{ color: '#282B4C' }} variant="body1">
            {t('TermsAndConditions.acept')}{' '}
            <Link href="/public/documents/Terminos y condiciones.pdf" rel="noopener" sx={{ fontWeight: 'bold' }} target="_blank">
              {t('TermsAndConditions.terms')}
            </Link>{' '}
            {t('TermsAndConditions.and')}{' '}
            <Link href="/public/documents/Politica de privacidad.pdf" rel="noopener" sx={{ fontWeight: 'bold' }} target="_blank">
              {t('TermsAndConditions.privacy')}
            </Link>
          </Box>
        }
      />
      <Box sx={{ ...errorStyles }}>{errors.termsAccepted}</Box>
    </Box>
  );
}
