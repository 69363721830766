import { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { Box, Collapse, Divider, Toolbar } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getSidebarOptionsFromGroups } from 'src/lib/util/navigation';

import { default as logoInverse } from '../../images/logo-inverse.svg';
import { default as logo } from '../../images/logo.svg';

const { useMsal } = require('@azure/msal-react');

const Sidebar = () => {
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const location = useLocation(); // Obtener la ruta actual
  const [pages, setPages] = useState();
  const [openIndex, setOpenIndex] = useState(-1); // Controla qué sublista está abierta
  const theme = useTheme();
  const { i18n, t } = useTranslation('nav');
  const locale = i18n.resolvedLanguage;

  const isDarkTheme = theme.palette.mode === 'dark';
  const tokenGroups = accounts[0]?.idTokenClaims?.groups;

  useEffect(() => {
    if (!pages && tokenGroups) {
      setPages(getSidebarOptionsFromGroups(tokenGroups));
    }
  }, [pages, tokenGroups]);

  useEffect(() => {
    // Mantener el menú expandido al cambiar de ruta
    pages?.forEach((page, index) => {
      if (page.subPages?.some(subPage => subPage.url === location.pathname)) {
        setOpenIndex(index);
      }
    });
  }, [location.pathname, pages]);

  const handleMenuOptionClick = useCallback(
    url => {
      if (url) navigate(url, { locale });
    },
    [locale, navigate]
  );

  // Método para alternar el estado de expansión de las sublistas
  const handleToggleSublist = index => {
    setOpenIndex(prevIndex => (prevIndex === index ? -1 : index)); // Alterna entre abrir/cerrar la sublista
  };

  return (
    <div>
      <Toolbar>
        <Box display="flex" flex={1} justifyContent="center">
          <Link to="/">
            <img alt="EntryPay" src={isDarkTheme ? logoInverse : logo} style={{ marginTop: 8 }} width={40} />
          </Link>
        </Box>
      </Toolbar>
      <Divider />
      <List>
        {pages?.map((page, index) => (
          <div key={page.url}>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  if (page.subPages?.length > 0) {
                    handleToggleSublist(index); // Desplegar si tiene sub-secciones
                  } else {
                    handleMenuOptionClick(page.url); // Navegar directamente si no tiene sub-secciones
                  }
                }}
              >
                <ListItemIcon sx={{ minWidth: '30px' }}>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={t(page.label)} />
                {/* Mostrar el icono de expandir solo si tiene sub-secciones */}
                {page.subPages?.length > 0 && (openIndex === index ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
            </ListItem>

            {/* Solo renderizar Collapse si existen sub-secciones */}
            {page.subPages?.length > 0 && (
              <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {page.subPages.map(subPage => (
                    <ListItemButton key={subPage.url} onClick={() => handleMenuOptionClick(subPage.url)} sx={{ pl: 4 }}>
                      <ListItemText primary={t(subPage.label)} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </div>
        ))}
      </List>
    </div>
  );
};

export default Sidebar;
