import DefaultLayout from 'src/components/layouts/DefaultLayout';
import RegistryComponent from 'src/components/pages/RegistryComponent';

export default function BusinessClub() {
  return (
    <DefaultLayout>
      <RegistryComponent />
    </DefaultLayout>
  );
}
