import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { EP_USR } from 'src/lib/constants';
import * as Yup from 'yup';

// Esquema de validación con Yup
const validationSchema = Yup.object().shape({
  active: Yup.boolean(),
  email: Yup.string().email('Debe ser un formato de correo válido').required('El correo es obligatorio'),
  firstName: Yup.string()
    .required('El nombre es obligatorio')
    .matches(/^[a-zA-Z\s]+$/, 'Solo se permiten letras'),
  group: Yup.string()
    .matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, 'Debe ser un UUID válido')
    .required('El tipo de usuario es obligatorio'),
  lastName: Yup.string()
    .required('El apellido es obligatorio')
    .matches(/^[a-zA-Z\s]+$/, 'Solo se permiten letras')
});

// Función para obtener las opciones del dropdown según el grupo actual (clave del usuario)

const getDropdownOptions = currentGroupKey => {
  console.log('Clave del grupo actual:', currentGroupKey);
  let filteredOptions = [];

  if (currentGroupKey === 'SUE' || currentGroupKey === 'DUE') {
    filteredOptions = EP_USR.filter(user => ['SUA', 'REE', 'CME', 'TSE'].includes(user.key));
  } else if (currentGroupKey === 'SUA' || currentGroupKey === 'DUE') {
    filteredOptions = EP_USR.filter(user => ['REE', 'CME', 'TSE'].includes(user.key));
  } else if (currentGroupKey === 'REE') {
    filteredOptions = EP_USR.filter(user => ['CME', 'TSE'].includes(user.key));
  } else if (currentGroupKey === 'CME') {
    filteredOptions = EP_USR.filter(user => ['TSE'].includes(user.key));
  } else {
    filteredOptions = [];
  }

  console.log('Opciones filtradas:', filteredOptions); // Verifica las opciones aquí
  return filteredOptions;
};

const UserSignupForm = ({ user }) => {
  // MSAL para obtener datos de la sesión
  const { accounts } = useMsal();
  const [currentGroupKey, setCurrentGroupKey] = useState(null);

  const initialValues = {
    active: user?.active || false,
    email: user?.email || '',
    firstName: user?.firstName || '',
    group: user?.group || '',
    lastName: user?.lastName || ''
  };

  const isEdit = Boolean(user);

  useEffect(() => {
    try {
      const sessionData = accounts[0]?.idTokenClaims;

      console.log('Datos de sesión al cargar el formulario:', sessionData);

      if (sessionData && sessionData.groups) {
        const groupIds = sessionData.groups.map(group => group.split('|')[0]);

        const userGroup = groupIds.find(groupId => EP_USR.some(user => user.id === groupId));
        const groupKey = EP_USR.find(user => user.id === userGroup)?.key;

        setCurrentGroupKey(groupKey);
      }
    } catch (error) {
      console.error('Error al adquirir los datos de sesión:', error);
    }
  }, [accounts]);

  const userTypes = getDropdownOptions(currentGroupKey);

  return (
    <Box sx={{ margin: 'auto', maxWidth: 400 }}>
      <Box sx={{ marginTop: '25px' }}>
        <Typography align="center" gutterBottom variant="h5">
          Formulario de registro de usuarios
        </Typography>
      </Box>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={values => {
          console.log(values);
          alert(JSON.stringify(values, null, 2));
        }}
        validationSchema={validationSchema}
      >
        {({ errors, touched, handleChange, values }) => (
          <Form>
            <FormControl fullWidth margin="normal">
              <TextField
                disabled={isEdit}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                label="Correo Electrónico"
                name="email"
                onChange={handleChange}
                type="email"
                value={values.email}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                label="Nombre"
                name="firstName"
                onChange={handleChange}
                value={values.firstName}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                label="Apellido"
                name="lastName"
                onChange={handleChange}
                value={values.lastName}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel id="group-label">Tipo de Usuario</InputLabel>
              <Select error={touched.group && Boolean(errors.group)} labelId="group-label" name="group" onChange={handleChange} value={values.group}>
                {userTypes.map(type => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.key}
                  </MenuItem>
                ))}
              </Select>
              {touched.group && errors.group && (
                <Box color="error.main" fontSize="0.75rem">
                  {errors.group}
                </Box>
              )}
            </FormControl>

            <FormControlLabel control={<Checkbox checked={values.active} name="active" onChange={handleChange} />} label="Activo" />

            <Button color="primary" fullWidth type="submit" variant="contained">
              Enviar
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default UserSignupForm;
