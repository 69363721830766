import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';

const events = ['click', 'mousemove', 'keypress', 'scroll', 'touchstart'];

const InactivityHandler = ({ children, defaultTimeout }) => {
  const { instance } = useMsal();
  const timerRef = useRef(null);

  const [timeout, setTimeoutValue] = useState(defaultTimeout);
  const [hasFetched, setHasFetched] = useState(false); // Nuevo estado para controlar la llamada al API

  useEffect(() => {
    const fetchSessionLife = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/last-session-life`);
        console.log('Session life:', response.data);

        if (response.data && response.data.vida_session) {
          setTimeoutValue(response.data.vida_session * 60000); // convertir a milisegundos (minutos * 60000)
          console.log(`Timeout calculado: ${response.data.vida_session * 60000} milisegundos (${response.data.vida_session} minutos)`);
        } else {
          console.log(`No se recibió vida_session, usando defaultTimeout: ${defaultTimeout} milisegundos`);
          setTimeoutValue(defaultTimeout);
        }
      } catch (error) {
        console.error('Error fetching session life:', error);
        setTimeoutValue(defaultTimeout);
      }
    };
    console.log(hasFetched);

    if (!hasFetched) {
      // Solo llama al API si no se ha hecho antes
      fetchSessionLife();
      setHasFetched(true); // Marca como que la llamada ya se realizó
    }
  }, [defaultTimeout, hasFetched]); // Dependencias: defaultTimeout y hasFetched

  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    console.log(`Configurando temporizador para ${timeout} milisegundos`);
    timerRef.current = setTimeout(() => {
      console.log('El tiempo de inactividad ha expirado, cerrando sesión...');

      instance.logoutRedirect(); // Cerrar sesión y redirigir
    }, timeout);
  }, [instance, timeout, timerRef]);

  useEffect(() => {
    console.log('Restableciendo temporizador...');
    resetTimer();

    events.forEach(event => window.addEventListener(event, resetTimer));

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      console.log('Eliminando eventos de temporizador...');
      events.forEach(event => window.removeEventListener(event, resetTimer));
    };
  }, [resetTimer, timerRef]);

  return <>{children}</>;
};

export default memo(InactivityHandler);
