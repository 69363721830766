import React from 'react';
import { Box, Grid, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function RegistryForm({ formData, handleChange, errors }) {
  const errorStyles = {
    color: '#d32f2f',
    fontSize: '0.75rem',
    minHeight: '1.125rem',
    textAlign: 'left',
    visibility: 'visible'
  };
  const { t } = useTranslation('registry');

  return (
    <form>
      <Grid container spacing={{ sm: 1 }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t('PersonalForm.Form.label4')}
            margin="normal"
            name="tipoPersona"
            onChange={handleChange}
            select
            sx={{
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: 'darkblue'
                },
                borderRadius: '20px'
              }
            }}
            value={formData.tipoPersona}
          >
            <MenuItem value="Física">{t('PersonalForm.Form.label5')}</MenuItem>
            <MenuItem value="Moral">{t('PersonalForm.Form.label6')}</MenuItem>
          </TextField>
        </Grid>
        {formData.tipoPersona === 'Física' && (
          <>
            <Grid item xs={12}>
              <TextField
                error={!!errors.nombres}
                fullWidth
                inputProps={{ maxLength: 30 }}
                label={t('PersonalForm.Form.label1')}
                margin="normal"
                name="nombres"
                onChange={handleChange}
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: 'darkblue'
                    },
                    borderRadius: '20px'
                  }
                }}
                value={formData.nombres}
              />
              <Box sx={errorStyles}>{errors.nombres}</Box>
              <TextField
                error={!!errors.apellidoPaterno}
                fullWidth
                inputProps={{ maxLength: 30 }}
                label={t('PersonalForm.Form.label2')}
                margin="normal"
                name="apellidoPaterno"
                onChange={handleChange}
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: 'darkblue'
                    },
                    borderRadius: '20px'
                  }
                }}
                value={formData.apellidoPaterno}
              />
              <Box sx={errorStyles}>{errors.apellidoPaterno}</Box>
              <TextField
                error={!!errors.apellidoMaterno}
                fullWidth
                inputProps={{ maxLength: 30 }}
                label={t('PersonalForm.Form.label3')}
                margin="normal"
                name="apellidoMaterno"
                onChange={handleChange}
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: 'darkblue'
                    },
                    borderRadius: '20px'
                  }
                }}
                value={formData.apellidoMaterno}
              />
              <Box sx={errorStyles}>{errors.apellidoMaterno}</Box>
            </Grid>
          </>
        )}
        {formData.tipoPersona === 'Moral' && (
          <Grid item xs={12}>
            <TextField
              error={!!errors.razonSocial}
              fullWidth
              inputProps={{ maxLength: 60 }}
              label={t('PersonalForm.Form.label7')}
              margin="normal"
              name="razonSocial"
              onChange={handleChange}
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'darkblue'
                  },
                  borderRadius: '20px'
                }
              }}
              value={formData.razonSocial}
            />
            <Box sx={errorStyles}>{errors.razonSocial}</Box>
            <TextField
              fullWidth
              inputProps={{ maxLength: 40 }}
              label={t('PersonalForm.Form.label8')}
              margin="normal"
              name="nombreComercial"
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'darkblue'
                  },
                  borderRadius: '20px'
                }
              }}
              value={formData.nombreComercial}
            />
          </Grid>
        )}
      </Grid>
    </form>
  );
}
