import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function RegistryEnd() {
  const { t } = useTranslation('registry');

  return (
    <>
      <Typography
        gutterBottom
        sx={{
          color: '#282B4C',
          fontSize: '1.8rem',
          fontWeight: 'bold',
          padding: { md: 8, xs: 4 },
          textAlign: 'center'
        }}
      >
        {t('End.message')} <Typography sx={{ color: '#e85d0c', fontSize: '1.8rem', fontWeight: 'bold' }}>{t('End.entrypay')}</Typography>
      </Typography>
      {/* <RedirectButton link="/" text={t('End.b_home')} /> */}
    </>
  );
}
