import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export default function RegistryFiscalDataForm({ formData, handleChange, errors }) {
  const errorStyles = {
    color: '#d32f2f',
    fontSize: '0.75rem',
    minHeight: '1.125rem',
    textAlign: 'left'
  };
  const { t } = useTranslation('registry');
  const [colonias, setColonias] = useState([]);

  const handleSearchClick = async () => {
    if (formData.codigoPostalFiscal.length === 5) {
      console.log('Fetching postal data...');
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/cp/json/${formData.codigoPostalFiscal}`);
        const data = response.data;
        setColonias(data.map(item => ({ label: item.d_asenta, value: item.d_asenta })));
        handleChange({ target: { name: 'estadoFiscal', value: data[0].d_estado } });
        handleChange({ target: { name: 'ciudadFiscal', value: data[0].d_ciudad } });
      } catch (error) {
        console.error('Error al obtener datos del CP:', error);
      }
    } else {
      console.error('El código postal debe tener exactamente 5 dígitos');
    }
  };
  return (
    <form>
      <Typography component="h2" paddingTop={2} sx={{ color: '#E85D0C', fontWeight: 'bold', my: 2, textAlign: 'left' }} variant="h5">
        {t('FiscalForm.title')}
      </Typography>
      <Grid container spacing={{ sm: 2 }}>
        <Grid item sm={6} xs={12}>
          <Box>
            <TextField
              error={!!errors.rfc}
              fullWidth
              inputProps={{ maxLength: 14, minLength: 13 }}
              label={t('FiscalForm.Form.label6')}
              margin="normal"
              name="rfc"
              onChange={handleChange}
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'darkblue'
                  },
                  borderRadius: '20px'
                }
              }}
              value={formData.rfc}
            />
            <Box sx={errorStyles}>{errors.rfc}</Box>
          </Box>
          <Box>
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <TextField
                error={!!errors.codigoPostalFiscal}
                fullWidth
                inputProps={{ maxLength: 5 }}
                label={t('FiscalForm.Form.label9')}
                margin="normal"
                name="codigoPostalFiscal"
                onChange={handleChange}
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: 'darkblue'
                    },
                    borderRadius: '20px 0 0 20px',
                    height: '53.6px'
                  }
                }}
                type="number"
                value={formData.codigoPostalFiscal}
              />
              <IconButton
                onClick={handleSearchClick} // Aquí puedes agregar la función para manejar el clic en el botón
                sx={{
                  '&:hover': {
                    backgroundColor: '#d35400'
                  },
                  backgroundColor: '#e85d0c',
                  borderRadius: '0 20px 20px 0',
                  color: '#fff',
                  height: '53.6px',
                  mt: 1,
                  width: '53.6px'
                }}
              >
                <SearchIcon />
              </IconButton>
            </Box>
            <Box sx={errorStyles}>{errors.codigoPostalFiscal}</Box>
          </Box>
          <Box>
            <TextField
              disabled
              fullWidth
              label={t('FiscalForm.Form.label7')}
              margin="normal"
              name="ciudadFiscal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'darkblue'
                  },
                  borderRadius: '20px'
                }
              }}
              value={formData.ciudadFiscal} // Valor asignado automáticamente a partir del código postal
            />
            <Box sx={errorStyles}>{errors.ciudadFiscal}</Box>
          </Box>

          <Box>
            <TextField
              fullWidth
              inputProps={{ maxLength: 10 }}
              label={t('FiscalForm.Form.label8')}
              margin="normal"
              name="numeroInteriorFiscal"
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'darkblue'
                  },
                  borderRadius: '20px'
                }
              }}
              value={formData.numeroInteriorFiscal}
            />
            <Box sx={errorStyles}>{errors.numeroInteriorFiscal}</Box>
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box>
            <TextField
              fullWidth
              inputProps={{ maxLength: 60 }}
              label={t('FiscalForm.Form.label1')}
              margin="normal"
              name="calleFiscal"
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'darkblue'
                  },
                  borderRadius: '20px'
                }
              }}
              value={formData.calleFiscal}
            />
            <Box sx={errorStyles}>{errors.calleFiscal}</Box>
          </Box>
          <Box>
            <TextField
              disabled
              fullWidth
              label={t('FiscalForm.Form.label4')}
              margin="normal"
              name="estadoFiscal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'darkblue'
                  },
                  borderRadius: '20px'
                }
              }}
              value={formData.estadoFiscal} // Valor asignado automáticamente a partir del código postal
            />
            <Box sx={errorStyles}>{errors.estadoFiscal}</Box>
          </Box>
          <Box>
            <TextField
              error={!!errors.coloniaFiscal}
              fullWidth
              label={t('FiscalForm.Form.label2')}
              margin="normal"
              name="coloniaFiscal"
              onChange={handleChange}
              required
              select
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'darkblue'
                  },
                  borderRadius: '20px'
                }
              }}
              value={formData.coloniaFiscal}
            >
              {/* Aquí se pueden agregar las opciones de colonias dinámicamente */}
              {colonias.map(colonia => (
                <MenuItem key={colonia.value} value={colonia.value}>
                  {colonia.label}
                </MenuItem>
              ))}
            </TextField>
            <Box sx={errorStyles}>{errors.coloniaFiscal}</Box>
          </Box>
          <Box>
            <TextField
              fullWidth
              inputProps={{ maxLength: 10 }}
              label={t('FiscalForm.Form.label3')}
              margin="normal"
              name="numeroExteriorFiscal"
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'darkblue'
                  },
                  borderRadius: '20px'
                }
              }}
              value={formData.numeroExteriorFiscal}
            />
            <Box sx={errorStyles}>{errors.numeroExteriorFiscal}</Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}
