import { useCallback, useContext } from 'react';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import IconButton from '@mui/material/IconButton';

import { ThemeContext } from '../../context/ThemeProvider';

const DarkModeToggle = () => {
  const { darkMode, setDarkMode } = useContext(ThemeContext);
  const toggleDarkMode = useCallback(() => setDarkMode(!darkMode), [darkMode, setDarkMode]);

  return (
    <IconButton onClick={toggleDarkMode} sx={{ padding: 0 }}>
      {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>
  );
};

export default DarkModeToggle;
