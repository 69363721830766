import React from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function TaxInformation({ data }) {
  const { t } = useTranslation('config');
  console.log('dataInfoUser:', data);

  // Aseguramos que los datos fiscales existan
  if (!data || !data.facturas) {
    return (
      <Box sx={{ padding: 2 }}>
        <Typography variant="h4">{t('profile.taxInformation')}</Typography>
        <Typography variant="body1">{t('profile.noGeneralTaxtInformation')}</Typography>
      </Box>
    );
  }

  const { rfc, razon_social, direccion_fiscal, nombre_comercial, numero_exterior, numero_interior, numero_telefonico, correo_electronico } =
    data.facturas;

  return (
    <Box sx={{ padding: 2 }}>
      <Typography gutterBottom variant="h4">
        {t('profile.taxInformation')}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                RFC:
              </TableCell>
              <TableCell>{rfc}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('profile.razonSocial')}:
              </TableCell>
              <TableCell>{razon_social}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('profile.fiscalAddress')}:
              </TableCell>
              <TableCell>{`${direccion_fiscal}, Ext: ${numero_exterior}, Int: ${numero_interior}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('profile.tradeName')}:
              </TableCell>
              <TableCell>{nombre_comercial}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('profile.phone')}:
              </TableCell>
              <TableCell>{numero_telefonico}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('profile.email')}:
              </TableCell>
              <TableCell>{correo_electronico}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default TaxInformation;
