import { memo } from 'react';
import { useMsal } from '@azure/msal-react';
import Typography from '@mui/material/Typography';
import ActivationLayout from 'src/components/layouts/ActivationLayout';
import DefaultLayout from 'src/components/layouts/DefaultLayout';
import { isBusinessPartnerStatusPending } from 'src/lib/util/authorization';

// import { loginRequest } from 'src/authConfig';

// const ProfileContent = () => {
//   const { instance, accounts } = useMsal();
//   const requestProfileData = () => {
//     // Silently acquires an access token which is then attached to a request for MS Graph data
//     instance
//       .acquireTokenSilent({
//         ...loginRequest,
//         account: accounts[0]
//       })
//       .then(response => {
//         console.log('token response:', response);
//         // callMsGraph(response.accessToken).then(response => setGraphData(response));
//       });
//   };
// };

const Dashboard = () => {
  const { accounts } = useMsal();

  if (isBusinessPartnerStatusPending(accounts)) {
    return <ActivationLayout />;
  }

  return (
    <DefaultLayout title="dashboard">
      <Typography sx={{ marginBottom: 2 }}>Dashboard content (TBD)</Typography>
      {/* <ProfileContent /> */}
    </DefaultLayout>
  );
};

export default memo(Dashboard);
