import { Box, Container, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function RegistryCuestions({ formData, handleChange, errors }) {
  const { t } = useTranslation('registry');

  const errorStyles = {
    color: '#d32f2f',
    fontSize: '0.75rem',
    minHeight: '1.125rem',
    textAlign: 'left',
    visibility: 'visible'
  };

  const getLogoSrc = () => {
    const { actividadComercial, localComercial, experienciaTI } = formData;
    if (actividadComercial === 'si' && localComercial === 'si' && experienciaTI === 'si') {
      formData.tipoSocio = 'BusinessPartner';
      return '/images/insignia/logoBusinessPartner.png';
    } else if (actividadComercial === 'si' && localComercial === 'no' && experienciaTI === 'si') {
      formData.tipoSocio = 'SystemProvider';
      return '/images/insignia/logoSystemProvider.png';
    } else if (actividadComercial) {
      formData.tipoSocio = 'Influencer';
      return '/images/insignia/logoInfluencer.png';
    }
    return null;
  };

  const logoSrc = getLogoSrc();

  return (
    <Container maxWidth={false} sx={{ color: '#282b4c', paddingY: { md: 4, xs: 2 } }}>
      <Typography component="h1" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'left' }} variant="h5">
        {t('club')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl component="fieldset" error={!!errors.actividadComercial} fullWidth>
            <Grid alignItems="center" container spacing={2}>
              <Grid item lg={8} sx={{ textAlign: 'left' }} xs={12}>
                <FormLabel component="legend">{t('Questions.question1')}</FormLabel>
              </Grid>
              <Grid item lg={4} xs={12}>
                <RadioGroup name="actividadComercial" onChange={handleChange} row value={formData.actividadComercial}>
                  <FormControlLabel control={<Radio />} label={t('Questions.answer1')} value="si" />
                  <FormControlLabel control={<Radio />} label={t('Questions.answer2')} value="no" />
                </RadioGroup>
              </Grid>
            </Grid>
            <Box sx={errorStyles}>{errors.actividadComercial}</Box>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" error={!!errors.experienciaTI} fullWidth>
            <Grid alignItems="center" container spacing={2}>
              <Grid item lg={8} sx={{ textAlign: 'left' }} xs={12}>
                <FormLabel component="legend">{t('Questions.question2')}</FormLabel>
              </Grid>
              <Grid item lg={4} xs={12}>
                <RadioGroup name="experienciaTI" onChange={handleChange} row value={formData.experienciaTI}>
                  <FormControlLabel control={<Radio />} label={t('Questions.answer1')} value="si" />
                  <FormControlLabel control={<Radio />} label={t('Questions.answer2')} value="no" />
                </RadioGroup>
              </Grid>
            </Grid>
            <Box sx={errorStyles}>{errors.experienciaTI}</Box>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" error={!!errors.localComercial} fullWidth>
            <Grid alignItems="center" container spacing={2}>
              <Grid item lg={8} sx={{ textAlign: 'left' }} xs={12}>
                <FormLabel component="legend">{t('Questions.question3')}</FormLabel>
              </Grid>
              <Grid item lg={4} xs={12}>
                <RadioGroup name="localComercial" onChange={handleChange} row value={formData.localComercial}>
                  <FormControlLabel control={<Radio />} label={t('Questions.answer1')} value="si" />
                  <FormControlLabel control={<Radio />} label={t('Questions.answer2')} value="no" />
                </RadioGroup>
              </Grid>
            </Grid>
            <Box sx={errorStyles}>{errors.localComercial}</Box>
          </FormControl>
        </Grid>

        {logoSrc && (
          <Grid item sx={{ textAlign: 'center' }} xs={12}>
            <img src={logoSrc} style={{ height: 100, width: 100 }} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
