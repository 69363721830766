import { useMsal } from '@azure/msal-react';
import { Route, Routes } from 'react-router-dom';
import { isAuthorized } from 'src/lib/util/navigation';

import pagesData from './pagesData';
import Unauthorized from './unauthorized';

const Router = () => {
  const { accounts } = useMsal();

  const tokenGroups = accounts[0]?.idTokenClaims?.groups;
  const pageRoutes = pagesData.map(({ path, title, element }) => {
    if (path === '*' || isAuthorized(tokenGroups, path)) {
      return <Route element={element} key={title} path={path} />;
    }
    return <Route element={<Unauthorized isLoggedIn={true} />} key={title} path={path} />;
  });

  return <Routes>{pageRoutes}</Routes>;
};

export default Router;
