import { memo } from 'react';
import EditDaysRemaining from 'src/components/EditDaysRemaining';
import EditSessionLifeMinutes from 'src/components/EditSessionMinutes';
import UserSignupForm from 'src/components/FormRegisterAndDeregistration';
import DefaultLayout from 'src/components/layouts/DefaultLayout';

const GlobalConfigPage = () => (
  <DefaultLayout title="globalConfig">
    <EditDaysRemaining />
    <EditSessionLifeMinutes />
    <UserSignupForm />
  </DefaultLayout>
);

export default memo(GlobalConfigPage);
