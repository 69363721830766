import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Grid, IconButton, Input, InputLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const errorStyles = {
  color: '#d32f2f',
  fontSize: '0.75rem',
  minHeight: '1.125rem',
  textAlign: 'left'
};

export default function RegistryDocumentationForm({ formData, handleChange, errors }) {
  const { t } = useTranslation('registry');

  const handleRemoveFile = field => {
    // Simula un evento de cambio para actualizar el estado en el componente padre
    handleChange({
      target: {
        name: field,
        type: 'file',
        value: null
      }
    });
  };

  const fileInput = (field, label) => {
    const file = formData[field]; // Asegúrate de que formData[field] contiene el archivo en sí
    return (
      <Box>
        <InputLabel sx={{ color: '#282B4C', fontSize: '1.2rem', fontWeight: 'bold', marginY: '3px', textAlign: 'left' }}>{label}</InputLabel>
        <Box sx={{ alignItems: 'center', display: 'flex', height: '53.6px' }}>
          <Input
            disabled
            sx={{
              '&.Mui-disabled': {
                backgroundColor: '#fff',
                color: '#000'
              },
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '20px 0 0 20px',
              flexGrow: 1,
              height: '53.6px',
              padding: '10px 14px'
            }}
            type="text"
            value={file ? file.name : t('Documentation.upload')}
          />
          <IconButton
            component="label"
            onClick={
              file
                ? e => {
                    e.preventDefault(); // Previene el evento del input de archivo
                    handleRemoveFile(field);
                  }
                : null
            }
            sx={{
              '&:hover': {
                backgroundColor: '#d35400'
              },
              backgroundColor: '#e85d0c',
              borderRadius: '0 20px 20px 0',
              color: '#fff',
              height: '53.6px',
              width: '53.6px'
            }}
          >
            {file ? <CloseIcon /> : <UploadFileIcon />}
            {!file && <input accept=".pdf,.jpg,.png" hidden name={field} onChange={handleChange} type="file" />}
          </IconButton>
        </Box>
        <Typography align="left" sx={{ display: 'block', marginTop: '4px' }} variant="caption">
          {t('Documentation.extensions')}
        </Typography>
        <Box sx={errorStyles}>{errors[field]}</Box>
      </Box>
    );
  };

  return (
    <>
      <Typography component="h1" paddingTop={2} sx={{ color: '#E85D0C', fontWeight: 'bold', my: 2, textAlign: 'left' }} variant="h5">
        Documentación
      </Typography>
      <Grid container spacing={{ sm: 2 }}>
        <Grid item sm={6} xs={12}>
          {fileInput('actaConstitutiva', t('Documentation.Documents.document1'))}
          {fileInput('identificacionOficial', t('Documentation.Documents.document2'))}
        </Grid>
        <Grid item sm={6} xs={12}>
          {fileInput('comprobanteDomicilio', t('Documentation.Documents.document3'))}
          {fileInput('situacionFiscal', t('Documentation.Documents.document4'))}
        </Grid>
      </Grid>
    </>
  );
}
