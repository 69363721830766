import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DefaultLayout from 'src/components/layouts/DefaultLayout';

import AttachedDocuments from '../../components/ProfileComponents/AttachedDocuments.jsx';
import ContactInformation from '../../components/ProfileComponents/ContactInformation.jsx';
import GeneralInformation from '../../components/ProfileComponents/GeneralInformation.jsx';
import TaxInformation from '../../components/ProfileComponents/TaxInformation.jsx';
import { EP_USR } from '../../lib/constants.js';

export default function ProfilePage() {
  const { t } = useTranslation('config');
  const [value, setValue] = useState('ContactInformation');
  const { accounts } = useMsal();
  const [sessionData, setSessionData] = useState(null);
  const [isBPEGroupPresent, setisBPEGroupPresent] = useState(false);
  const [dataInfoUser, setDataInfoUser] = useState(null);
  const [noDataError, setNoDataError] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [loading, setLoading] = useState(true); // Estado de carga
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionData) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/contactosUsuario/${sessionData.sub}`);
          console.log('response', response);
          if (
            response.data.messages === 'Contacto no encontrado' ||
            response.data.messages === 'Datos generales no encontrados' ||
            response.data.messages === 'Datos fiscales no encontrados'
          ) {
            console.log('No data found');
          } else {
            if (response.data && Object.keys(response.data).length > 0) {
              const { contacto, datosGenerales, facturas } = response.data;
              if (contacto || datosGenerales || facturas) {
                setDataInfoUser(response.data);
                setNoDataError(false);
              }
            }
          }
        } catch (error) {
          setFetchError(true);
          setDataInfoUser(null);
        } finally {
          setLoading(false); // Cambia a falso cuando la llamada a la API termina
        }
      };

      fetchData();
    }
  }, [sessionData, navigate]);

  useEffect(() => {
    try {
      const sessionData = accounts[0].idTokenClaims;
      setSessionData(sessionData);

      if (sessionData && sessionData.groups) {
        const groupPresent = sessionData.groups.some(group => group.startsWith(EP_USR.find(u => u.key === 'BPE')?.id));
        setisBPEGroupPresent(groupPresent);
      }
    } catch (error) {
      console.error('Error acquiring token:', error);
    }
  }, [accounts]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Muestra el indicador de carga mientras se espera la redirección o los datos
  if (loading) {
    return (
      <DefaultLayout>
        <div style={{ alignItems: 'center', display: 'flex', height: '100vh', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      </DefaultLayout>
    );
  }

  if (fetchError) {
    return (
      <DefaultLayout>
        <div style={{ alignItems: 'center', display: 'flex', height: '100vh', justifyContent: 'center' }}>
          <p>{t('profile.fetchError')}</p>
        </div>
      </DefaultLayout>
    );
  }

  if (noDataError) {
    return (
      <DefaultLayout>
        <div style={{ alignItems: 'center', display: 'flex', height: '100vh', justifyContent: 'center' }}>
          <p>{t('profile.noData')}</p>
        </div>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <Box sx={{ width: '100%' }}>
        <Tabs aria-label="profile tabs example" indicatorColor="secondary" onChange={handleChange} textColor="secondary" value={value}>
          <Tab label={t('profile.contactInformation')} value="ContactInformation" />
          <Tab disabled={!isBPEGroupPresent} label={t('profile.generalInformation')} value="GeneralInformation" />
          <Tab disabled={!isBPEGroupPresent} label={t('profile.taxInformation')} value="TaxInformation" />
          <Tab disabled={!isBPEGroupPresent} label={t('profile.attachedDocuments')} value="AttachedDocuments" />
        </Tabs>

        <Box sx={{ p: 3 }}>
          {value === 'ContactInformation' && <ContactInformation data={dataInfoUser} setDataInfoUser={setDataInfoUser} />}
          {value === 'GeneralInformation' && isBPEGroupPresent && <GeneralInformation data={dataInfoUser} />}
          {value === 'TaxInformation' && isBPEGroupPresent && <TaxInformation data={dataInfoUser} />}
          {value === 'AttachedDocuments' && isBPEGroupPresent && <AttachedDocuments userId={sessionData.sub} />}
        </Box>
      </Box>
    </DefaultLayout>
  );
}
