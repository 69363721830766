import { memo } from 'react';
import { Typography } from '@mui/material';
import DefaultLayout from 'src/components/layouts/DefaultLayout';

const NotFoundPage = () => {
  return (
    <DefaultLayout>
      <Typography>404</Typography>
    </DefaultLayout>
  );
};

export default memo(NotFoundPage);
