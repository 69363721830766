import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function LinearProgressWithLabel({ value }) {
  return (
    <Box sx={{ alignItems: 'center', display: 'flex' }}>
      <Box sx={{ mr: 1, width: '100%' }}>
        <LinearProgress sx={{ '& .MuiLinearProgress-bar': { backgroundColor: '#e85d0c' } }} value={value} variant="determinate" />
      </Box>
    </Box>
  );
}

function DaysLeftForElimination({ data, days }) {
  const { t } = useTranslation('config');

  // Si `days` es nulo o undefined, asigna 30 días como valor predeterminado.
  const totalDays = days || 30;

  // Obtener la fecha de creación desde el token `createdDateTime`.
  const createdDateTime = data.createdDateTime;

  // Si existe `createdDateTime`, calcular los días transcurridos.
  const daysSinceCreation = createdDateTime
    ? Math.floor((Date.now() / 1000 - createdDateTime) / (60 * 60 * 24)) // Diferencia en días
    : 0; // Si no hay fecha de creación, asignar 0 días transcurridos.

  // Calcular los días restantes para la eliminación.

  const daysLeftForElimination = totalDays - daysSinceCreation;

  // Asegurar que los días restantes no sean negativos.
  const daysLeft = daysLeftForElimination > 0 ? daysLeftForElimination : 0;

  // Cálculo inverso del progreso: 100% al inicio, 0% cuando los días se acaben.
  const progress = ((daysLeft / totalDays) * 100).toFixed(2);

  return (
    <Box
      sx={{
        backgroundColor: '#f4f4f4',
        borderRadius: 4,
        mb: 4,
        padding: 2,
        textAlign: 'center'
      }}
    >
      <Typography sx={{ color: 'black', fontWeight: 'bold' }} variant="h4">
        {t('acountDeletionDays.daysLeftForElimination')}
      </Typography>
      <Typography sx={{ color: '#353964', fontSize: '50px' }} variant="body1">
        {daysLeft}
      </Typography>
      <Box sx={{ mt: 2, width: '100%' }}>
        <LinearProgressWithLabel value={progress} />
      </Box>
    </Box>
  );
}

export default DaysLeftForElimination;
